import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Wrapper from "../components/Wrapper"
import Link from "../components/Link"
import Input from "../components/Input"
import { style as buttonStyle } from "../components/ButtonLink"
import onSubmit from "../utils/onSubmit"

export const query = graphql`
  query BugReportQuery {
    site {
      siteMetadata {
        description
        bugReportUrl
        bugTrackerUrl
        userLogLocations {
          windows
          linux
          apple
        }
      }
    }
  }
`

const BugReport = ({
  data: {
    site: {
      siteMetadata: { bugReportUrl, bugTrackerUrl, userLogLocations },
    },
  },
}) => {
  return (
    <Layout>
      <SEO title="Bug Report" />
      <Wrapper padding="4em">
        <h2>Bug Report</h2>
        <p>
          Thank you for taking the time to do this! It helps us a ton. To submit
          a bug, feel free to:
        </p>
        <ul>
          <li>
            Write to us on{" "}
            <a href={`mailto: andrew@onanodyssey.com`}>
              andrew@onanodyssey.com
            </a>
          </li>
          {bugTrackerUrl && (
            <li>
              Submit it on our <Link to={bugTrackerUrl}>bug tracker</Link>
            </li>
          )}
          <li>
            Submit it through the game (click the "report" button in game)
          </li>
          <li>Or fill the form below</li>
        </ul>
        <form
          encType="multipart/form-data"
          method="post"
          action={bugReportUrl}
          onSubmit={onSubmit}
        >
          <Input
            name="email"
            required={true}
            type="email"
            label="email"
            help="Your email"
          />
          <Input
            name="os-name"
            type="text"
            label="OS name"
            help="(Window, Mac, Linux)"
          />
          <Input
            name="description"
            required={true}
            type="textarea"
            label="What happened"
            help="Please describe the bug. What were you doing? What did you expect to happen? What happened instead?"
            cols={40}
            rows={20}
          />
          <Input
            name="log"
            type="file"
            label="Log file"
            help={
              <ul>
                You can attach your log, which will help us reproduce the
                problem. You will find your log file in...{" "}
                {Object.keys(userLogLocations).map(os => (
                  <li key={os}>
                    {os}: {userLogLocations[os]}
                  </li>
                ))}
              </ul>
            }
          />
          <button
            css={css`
              ${buttonStyle({ background: "#2bb1a4", color: "#32343b" })};
              margin: 1em auto;
              font-size: 1.3em;
            `}
          >
            submit
          </button>
        </form>
      </Wrapper>
    </Layout>
  )
}

export default BugReport
