import React from "react"
import { css } from "@emotion/core"

export interface InputProps {
  name: string
  required?: boolean
  type?: "textarea" | "text" | "email" | "number" | "file"
  label?: string
  help?: string | JSX.Element
  placeholder?: string
  cols?: number
  rows?: number
}
let inputIds = 0

export default ({ name, required, type, label, help, ...rest }: InputProps) => {
  const id = `input-` + inputIds++
  const tag = type === "textarea" ? "textarea" : "input"
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
        max-width: 40em;
        margin: 1em auto;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          flex-basis: 100%;
        `}
      >
        <label
          css={css`
            display: inline-block;
          `}
          htmlFor={id}
        >
          <span>
            {label || name}

            {required && (
              <em
                css={css`
                  color: #fe3333;
                `}
              >*
              </em>
            )}
          </span>
        </label>
        {help && (
          <label
            css={css`
              display: inline-block;
              font-size: 0.8em;
            `}
            htmlFor={id}
          >
            <span>{help}</span>
          </label>
        )}
      </div>
      {React.createElement(tag, {
        name,
        id,
        required,
        type,
        css: css`
          flex-basis: 100%;
          padding: 1em;
        `,
        ...rest,
      })}
    </div>
  )
}
