import { FormEvent } from "react"
import { toast } from "react-toastify"

const disable = (btn?: HTMLButtonElement | HTMLInputElement) => {
  if(!btn){ return }
  btn.disabled = true
  if(btn.tagName === 'INPUT'){
    const text = btn.value
    btn.value = 'please wait...'
    return () => {
      btn.disabled = false
      btn.value = text
    }
  }else{
    const text = btn.textContent
    btn.textContent = 'please wait...'
    return () => {
      btn.disabled = false
      btn.textContent = text
    }
  }
}

export default async (evt: FormEvent<HTMLFormElement>) => {
  evt.preventDefault()
  const form = evt.target as HTMLFormElement
  const url = form.action
  const props = {
    method: form.method.toUpperCase(),
    body: new FormData(form),
  }
  const submitButton = form.querySelector(
    '[type="submit"], button'
  ) as HTMLButtonElement
  const enable = disable(submitButton)
  try {
    const response = await fetch(url, props)
    if (response.status < 400) {
      toast.success(
        "Thank you for this! We will treat your report as soon as we can"
      )
    } else {
      if (response.status === 409) {
        toast.error(
          "There was an error submitting your report. Can you please submit it via one of the other methods?"
        )
      } else {
        toast.warn(
          "Some fields were not properly filled. Please review and send again. Alternatively, you can send your report via another method"
        )
      }
    }

    enable()
  } catch (e) {
    toast.error(
      "There was an error submitting your report. Can you please submit it via one of the other methods?"
    )
    enable()
  }
}
